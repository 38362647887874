<template>
  <v-card class="ma-2" style="text-align: center;">
    <page-title title="Reports" icon="mdi-equal-box"/>
    <v-container fluid grid-list-md px-0 py-0>
      <v-row class="ma-2">
        <v-col :key="key" md="3" sm="6" xs="12" v-if="isAdmin || checkAccess(item.reportType)" v-for="(item,key ) in reportItems">
          <v-card
              :color="item.color"
              class="mx-auto text-sm-center"
              max-width="100%"
              :style="{'background-color': item.color + ' !important'}"
          >
            <v-card-text class="white--text" style="padding: 0; margin: 0">
              <div style="padding: 12px">
                <div class="text-sm-center text-xs-center">
                  <v-icon class="icon-text">{{item.icon}}</v-icon>
                </div>
                <div class="btn-text text-sm-center text-xs-center">{{item.title}}</div>
              </div>
              <v-divider/>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        large
                        :color="item.color"
                        class="icon-text widget-btn"
                        @click="onClickReportsHandler(item.reportType)"
                        v-on="on"
                    >
                      <v-icon right>visibility</v-icon>
                    </v-btn>
                  </template>
                  <span>{{'Click to load ' + item.title + ' report'}}</span>
                </v-tooltip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import PageTitle from "../components/PageTitle";
import IsAuthenticated from "../middleware/IsAuthenticated";
export default {
  name: "Reports",
  mixins: [IsAuthenticated],
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Reports | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {PageTitle},
  data() {
    return {
      reportItems: [
        {
          title: 'Counters',
          reportType: 'counters',
          icon: 'trending_up',
          color: 'rgba(247, 105, 33, 0.9)'
        },
        {
          title: 'Invoices',
          reportType: 'invoices',
          icon: 'transform',
          color: 'rgba(107, 117, 21, 0.9)'
        },
        {
          title: 'Site',
          reportType: 'sites',
          icon: 'business',
          color: 'rgba(5, 22, 86, 0.9)'
        },
        {
          title: 'Site Summary',
          reportType: 'site-summary',
          icon: 'business',
          color: 'rgba(55,89,238,0.9)'
        },
        {
          title: 'Client Summary',
          reportType: 'client-summary',
          icon: 'mdi-chart-timeline',
          color: 'rgba(13,75,116,0.9)'
        },
        {
          title: 'Statement',
          reportType: 'statements',
          icon: 'mdi-cash-multiple',
          color: 'rgba(5, 22, 86, 0.9)'
        },
        {
          title: 'Sites Statement',
          reportType: 'sites-statement',
          icon: 'mdi-sale',
          color: 'rgba(107, 117, 21, 0.9)'
        },
        {
          title: 'Staff Reports',
          reportType: 'staffs',
          icon: 'mdi-account',
          color: 'rgb(132,132,236)'
        },
      ]
    }
  },
  methods: {
    onClickReportsHandler(routePath) {
      this.$router.push({path: `reports/${routePath}`});
    }
  }
}
</script>

<style scoped lang="scss">
.icon-text {
  font-size: 60px;
  color: white;
  .v-icon {
    font-size: 24px !important;
  }
}

.btn-text {
  font-size: 25px;
}

.widget-btn {
  width: 100%;
  padding: 0px;
  margin: 0px;
}
</style>